import React from "react"
import { Link } from "gatsby"
import cx from "classnames"
import marked from "marked"
import Container from "components/Container"
import Img from "gatsby-image/withIEPolyfill"
import TitleBlock from "./TitleBlock"
import styles from "./ContentWithImageAndList.module.css"

export const ContentWithImageAndList = (props) => (
  <div className={cx({ [styles.root]: styles.root, }, props.className, {
    [styles.gray]: props.bgColor === 'isGray',
    [styles.gradient]: props.bgColor === 'isGradient',
    [styles.noPaddingTop]: props.paddingTop === 'noPadding',
    [styles.reverse]: props.layout === 'reverse',
    [styles.nocontentimage]: props.layout === 'nocontentimage',
  })}>
    <Container>
        <div className={styles.rootWrap}>
          {props.image ? (
            <div className={styles.image}>
              {props.image.extension === "svg" ? (
                <img alt={props.imageAltText ? props.imageAltText : props.name} title={props.name} src={props.image.publicURL} width="600" height="500" loading="lazy"/>
              ) : props.image.childImageSharp ? (
                <Img
                  loading="lazy"
                  className={styles.imageMain}
                  objectFit="contain"
                  alt={props.imageAltText ? props.imageAltText : props.name}
                  title={props.name}
                  fluid={props.image.childImageSharp.fluid}
                />
              ) : null}
            </div>
          ) : null}
          <div className={styles.content}>
            <div className={styles.contentWrap}>
              {props.title ? (
                <TitleBlock isLarger>{props.title}</TitleBlock>
              ) : null}
              {props.text ? (
                <div className={styles.text} dangerouslySetInnerHTML={{ __html: marked(props.text || "") }} />
              ) : null}
            </div>
          </div>
        </div>
        {props.subTitle ? (
          <h3 className={styles.subTitle}>{props.subTitle}</h3>
        ) : null}
        <div className={styles.cards}>
          {(props.textWithCards || []).filter(Boolean).map((item, i) => (
              <div className={styles.card}>
                  {item.image ? (
                  <div className={styles.cardImage} key={i}>
                    {item.image.extension === "svg" ? (
                      <img alt={item.image.name} title={item.image.name} src={item.image.publicURL} loading="lazy" width="85" height="85"/>
                    ) : item.image.childImageSharp ? (
                      <Img 
                      loading="lazy"
                      className={styles.img}
                      objectFit="contain"
                      alt={item.image.title}
                      title={item.image.title}
                      fluid={item.image.childImageSharp.fluid}
                      />
                    ): null}
                  </div>
                  ) : null}
                  {item.preTitle ? (
                    <div>
                      <p className={styles.preTitle}>{item.preTitle}</p>
                      <h4 className={styles.cardTitle} dangerouslySetInnerHTML={{ __html: item.title || "" }} />
                    </div>
                    ) : <h4 className={styles.cardTitle} dangerouslySetInnerHTML={{ __html: item.title || "" }} />}
                  {item.text ? <div className={styles.desc} dangerouslySetInnerHTML={{ __html: marked(item.text || "") }} /> : null}
              </div>
          ))}
        </div>
        {props.buttonText ? (
          <Link className={styles.button} to={`${props.buttonUrl}`}>{props.buttonText}</Link>
        ) : null}
    </Container>
  </div>
)

export default ContentWithImageAndList
