import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import marked from "marked"
import Container from "components/Container"
import TitleBlock from "./TitleBlock"
import cx from "classnames"
import styles from "./PpcMiddleCTA.module.css"
import HubspotForm from "components/HubspotForm"

export const PpcMiddleCTA = (props) => (
  <div className={cx({ [styles.root]: styles.root, }, props.className, {
    [styles.gray]: props.bgColor === 'isGray',
    [styles.gradient]: props.bgColor === 'isGradient',
    [styles.bottomTitle]: props.layout === 'bottomTitle',
  })} id={props.sectionID}>
    <Container>
      <div className={styles.content}>
        <div className={styles.contentMain}>
          <div className={styles.contentMainWrap}>
            {props.image && props.image.extension === "svg" ? (
              <div className={styles.icon}>
                <img alt={props.title} title={props.title} src={props.image.publicURL} loading="lazy" width="95" height="95"/>
              </div>
            ) : props.image && props.image.childImageSharp ? (
              <Img
                loading="lazy"
                className={styles.icon}
                objectFit="contain"
                alt={props.title}
                title={props.title}
                fluid={props.image.childImageSharp.fluid}
              />
            ) : null}
            <TitleBlock>{props.title}</TitleBlock>
          </div>
          {props.description ? <div className={styles.description} dangerouslySetInnerHTML={{ __html: marked(props.description || "") }} /> : null}
        </div>
        {props.ppcMiddleCTA ? (
          <div className={styles.form}>
            {(props.ppcMiddleCTA || []).filter(Boolean).map((item, i) => (
                <HubspotForm id={item.form} portalId={item.portalId} />
            ))}
          </div>
        ) : (
          <div className={styles.contentButton}>
            {props.buttonText ? (
                <a className={styles.button}
                  href={props.buttonUrl}
                  target={props.buttonUrl !== "#contact-form" ? '_blank' : ''}
                  rel={props.buttonUrl !== "#contact-form" ? 'noreferrer' : ''}
                >{props.buttonText}
                </a>
            ) : null}
          </div>
        )}
      </div>
    </Container>
  </div>
)

export default PpcMiddleCTA
