import React from "react"
import marked from "marked"
import { Link } from "gatsby"
import Container from "components/Container"
import cx from "classnames"
import Img from "gatsby-image/withIEPolyfill"
import TitleBlock from "./TitleBlock"
import styles from "./TextWithImage.module.css"

export const TextWithImage = (props) => (
  <div className={cx({ [styles.root]: styles.root, }, props.className, {
    [styles.noPaddingBottom]: props.paddingBottom === 'noPadding', [styles.bottomImage]: props.layout === 'bottomImage'
  })}>
    <Container>
      {(props.textWithImage || []).filter(Boolean).map((item, i) => (
        <div className={styles.logocontainer}>
          <TitleBlock isLarger><span dangerouslySetInnerHTML={{ __html: item.title || ""}} /></TitleBlock>
          {item.subtitle ? <div className={styles.subtitle} dangerouslySetInnerHTML={{ __html: marked(item.subtitle || "") }} /> : null}
          {item.image || item.video ? (
          <div className={cx({ [styles.flex]: styles.flex, }, props.className, {
            [styles.narrow]: props.layout === 'isNarrow'
          })}>
            <div key={i} className={cx({ [styles.img]: styles.img, }, props.className, {
            [styles.videoAdjustment]: item.video,
          })}>
            {item.image ? (
                item.image.extension === "svg" ? (
                    <img alt={item.imageAltText ? item.imageAltText : item.image.title} title={item.image.title} src={item.image.publicURL} loading="lazy" width="500" height="500"/>
                ) : item.image.childImageSharp ? (
                  <Img 
                  loading="lazy"
                  className={styles.gridimage}
                  objectFit="contain"
                  alt={item.imageAltText ? item.imageAltText : item.image.title}
                  title={item.image.title}
                  fluid={item.image.childImageSharp.fluid}
                  />
                ) : null
            ) : item.video ? (
                  <div className={styles.video}>
                    <iframe
                      src={item.video}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title={item.title}
                    />
                  </div>
            ) : null}
            </div>
            {item.text ? <div className={styles.text} dangerouslySetInnerHTML={{ __html: marked(item.text || "") }} /> : null}
          </div>
          ) : (
            item.text ? <div className={styles.centerText} dangerouslySetInnerHTML={{ __html: marked(item.text || "") }} /> : null
          ) }
          {props.secondImage ? (
            <div className={styles.mainimage}>
                {props.secondImage.extension === "svg" ? (
                <img alt={props.secondImage.name} title={props.secondImage.name} src={props.secondImage.publicURL} loading="lazy" width="500" height="500"/>
                ) : props.secondImage.childImageSharp ? (
                <Img
                    loading="lazy"
                    objectFit="fit"
                    alt={props.secondImage.name}
                    title={props.secondImage.name}
                    fluid={props.secondImage.childImageSharp.fluid}
                />
                ) : null}
            </div>
          ) : null}
          {props.tableData ? (
            <div className={styles.tableData}>
              {props.tableData.filter(Boolean).map((table, i) => (
                  <table key={i} className={table.className}>
                    {(table.tableHead || []).length > 0 ? (
                      <thead>
                        <tr>
                          {table.tableHead.filter(Boolean).map((item, i) => (
                            <th key={i} className={styles.thLabel}>
                              <span>{item.label}</span>
                            </th>
                          ))}
                        </tr>
                      </thead>
                    ) : null}
                    {(table.tableRow || []).length > 0 ? (
                      <tbody>
                        {table.tableRow.filter(Boolean).map((item, i) => (
                          <tr key={i}>
                            {item.row.filter(Boolean).map((row, i) => (
                              <td key={i} className={styles.tdValue}>
                                <span>
                                  {row.value}
                                  {(row.icon) ? (
                                    <i></i>
                                  ) : null}
                                </span>
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    ) : null}
                  </table>
              ))}
            </div>
          ) : null}
          {item.buttonText ? (
            <Link className={styles.button} to={`${item.buttonUrl}`}>{item.buttonText}</Link>
          ) : null}
        </div>
      ))}
    </Container>
  </div>
)

export default TextWithImage
