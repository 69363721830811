import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import Container from "components/Container"
import TitleBlock from "./TitleBlock"
import styles from "./ListWithImageAndDescription.module.css"

export const ListWithImageAndDescription = (props) => (
  <Container className={styles.root}>
    <div className={styles.wrap}>
      {props.image && props.image.extension === "svg" ? (
        <div className={styles.image}>
          <img alt={props.title} title={props.title} src={props.image.publicURL} loading="lazy" width="200" height="200"/>
        </div>
      ) : props.image && props.image.childImageSharp ? (
        <div className={styles.image}>
          <Img
            loading="lazy"
            objectFit="contain"
            alt={props.title}
            title={props.title}
            fluid={props.image.childImageSharp.fluid}
          />
        </div>
      ) : null}

      <div className={styles.info}>
        <TitleBlock>{props.title}</TitleBlock>
        <p>{props.description}</p>
      </div>
    </div>

    <div className={styles.list}>
      {(props.listWithImageAndDescription || []).filter(Boolean).map((item, i) => (
        <div key={i}>
          <h4>{item.title}</h4>
          <p>{item.description}</p>
        </div>
      ))}
    </div>
  </Container>
)

export default ListWithImageAndDescription
