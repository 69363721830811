import React from "react"
import Container from "components/Container"
import styles from "./FullWidthHighlighted.module.css"

export const FullWidthHighlighted = (props) => (
  <div className={styles.root}>
    <Container className={styles.wrapper}>
      <h2 className={styles.title}>{props.title}</h2>
      <p className={styles.description}>{props.description}</p>
    </Container>
  </div>
)

export default FullWidthHighlighted
