import React from "react"
import marked from "marked"
import { Link } from "gatsby"
import Container from "components/Container"
import cx from "classnames"
import Img from "gatsby-image/withIEPolyfill"
import TitleBlock from "./TitleBlock"
import styles from "./ContentWithGraphAndTable.module.css"

export const ContentWithGraphAndTable = (props) => (
  <div className={cx({ [styles.root]: styles.root, }, props.className, {
    [styles.noPaddingBottom]: props.paddingBottom === 'noPadding',
    [styles.bottomImage]: props.layout === 'bottomImage',
    [styles.gray]: props.bgColor === 'isGray',
  })}>
    <Container>
      <div className={styles.wrapper}>
        {props.title ? (
            <TitleBlock isLarger><span dangerouslySetInnerHTML={{ __html: props.title || ""}} /></TitleBlock>
        ) : null}
        {props.subTitle ? <div className={styles.subtitle} dangerouslySetInnerHTML={{ __html: marked(props.subTitle || "") }} /> : null}
        {props.contentWithList ? (
          <div className={styles.list}>
            {(props.contentWithList || []).filter(Boolean).map((list, i) => (
              <div className={styles.listinner}>
                {list.secondTitle ? (
                    <h3>{list.secondTitle}</h3>
                ) : null}
                <div className={styles.listmain}>
                  <ul>
                    {(list.list || []).filter(Boolean).map((item, i) => (
                      <li>
                        {item.link ? (
                          <Link to={`${item.link}`}>{item.label}</Link>
                        ) : item.label}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        ) : null}
        {props.secondTitle ? (
            <h3 className={styles.secondtitle}>{props.secondTitle}</h3>
        ) : null}
        {props.image ? (
            props.image.extension === "svg" ? (
              <div className={styles.gridimage}>
                <img alt={props.imageAltText ? props.imageAltText : props.image.title} title={props.image.title} src={props.image.publicURL} loading="lazy" width="500" height="500"/>
              </div>
            ) : props.image.childImageSharp ? (
              <Img 
              loading="lazy"
              className={styles.gridimage}
              objectFit="contain"
              alt={props.imageAltText ? props.imageAltText : props.image.title}
              title={props.image.title}
              fluid={props.image.childImageSharp.fluid}
              />
            ) : null
        ) : null}
        {props.tableData ? (
          props.tableData.filter(Boolean).map((table, i) => (
            <div className={styles.tableData}>
                {table.title && !props.title ? (
                  <TitleBlock isLarger><span dangerouslySetInnerHTML={{ __html: table.title || ""}} /></TitleBlock>
                ) : (
                  <h3 className={styles.secondtitle}>{table.title}</h3>
                )}
                {table.description ? <div className={styles.desc} dangerouslySetInnerHTML={{ __html: marked(table.description || "") }} /> : null}
                <div className={styles.tableDataContainer}>
                    <table key={i} className={table.className}>
                      {(table.tableHead || []).length > 0 ? (
                        <thead>
                          <tr>
                            {table.tableHead.filter(Boolean).map((item, i) => (
                              <th key={i} className={styles.thLabel}>
                                <span>{item.label}</span>
                              </th>
                            ))}
                          </tr>
                        </thead>
                      ) : null}
                      {(table.tableRow || []).length > 0 ? (
                        <tbody>
                          {table.tableRow.filter(Boolean).map((item, i) => (
                            <tr key={i}>
                              {item.row.filter(Boolean).map((row, i) => (
                                <td key={i} className={styles.tdValue}>
                                  <span>
                                    {(row.icon) ? (
                                      <i></i>
                                    ) : null}
                                    {row.value ? <span dangerouslySetInnerHTML={{ __html: marked(row.value || "") }} /> : null}
                                  </span>
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      ) : null}
                    </table>
                </div>
                {table.additionals ? (
                  <div className={styles.addon}>
                    {table.secondTitle ? <div className={styles.addonTitle} dangerouslySetInnerHTML={{ __html: marked(table.secondTitle || "") }} /> : null}
                    <div className={styles.addonList}>
                      {table.additionals.filter(Boolean).map((item, i) => 
                        (item.link ? (
                          <Link to={`${item.link}`}>{item.label}</Link>
                        ) : (
                          <div>
                          <span dangerouslySetInnerHTML={{ __html: marked(item.label || "") }} />
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                ) : null}
            </div>
          ))
        ) : null}
        {props.buttonText ? (
          <Link className={styles.button} to={`${props.buttonUrl}`}>{props.buttonText}</Link>
        ) : null}
      </div>
    </Container>
  </div>
)

export default ContentWithGraphAndTable