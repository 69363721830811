import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import Container from "components/Container"
import TitleBlock from "./TitleBlock"
import styles from "./IconsGrid.module.css"

export const IconsGrid = (props) => (
  <Container className={styles.root}>
    <TitleBlock>{props.title}</TitleBlock>
    {props.description ? <p className={styles.desc}>{props.description}</p> : null}
    <div className={styles.icons}>
      {(props.iconsGridList || []).filter(Boolean).map((item, i) => (
        <div className={styles.item} key={i}>
          {item.icon && item.icon.extension === "svg" ? (
            <div className={styles.icon}>
              <img alt={item.title} title={item.title} src={item.icon.publicURL} loading="lazy" width="80" height="80"/>
            </div>
          ) : item.icon && item.icon.childImageSharp ? (
            <div className={styles.icon}>
              <Img
                loading="lazy"
                objectFit="contain"
                alt={item.title}
                title={item.title}
                fluid={item.icon.childImageSharp.fluid}
              />
            </div>
          ) : null}
          <h4>{item.title}</h4>
          <p>{item.description}</p>
        </div>
      ))}
    </div>
  </Container>
)

export default IconsGrid
