import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import TitleBlock from "./TitleBlock"
import Container from "components/Container"
import * as blobs from "blobs/v2"
import styles from "./GridWithIconsAndImage.module.css"

const Item = (props) => (
  <div className={styles.item}>
    {props.icon && props.icon.extension === "svg" ? (
      <div className={styles.icon}>
        <img alt={props.title} title={props.title} src={props.icon.publicURL} />
      </div>
    ) : props.icon && props.icon.childImageSharp ? (
      <div className={styles.icon}>
        <Img
          loading="lazy"
          objectFit="contain"
          alt={props.title}
          title={props.title}
          fluid={props.icon.childImageSharp.fluid}
        />
      </div>
    ) : null}
    <h4>{props.title}</h4>
    <p>{props.description}</p>
  </div>
)

export const GridWithIconsAndImage = (props) => (
  <Container className={styles.root}>
    {props.title ? <TitleBlock>{props.title}</TitleBlock> : null}

    <div className={styles.grid}>
      {(props.gridWithIconsAndImageList || [])[0] ? (
        <Item {...props.gridWithIconsAndImageList[0]} />
      ) : null}

      {props.image ? (
        props.image.extension === "svg" ? (
          <div className={styles.image}>
            <img alt="grid-with-icons" title="grid-with-icons" src={props.image.publicURL} loading="lazy"/>
          </div>
        ) : (
          <div className={styles.image}>
            <svg viewBox="0 0 300 300">
              <linearGradient id="gradient" gradientUnits="userSpaceOnUse">
                <stop stopColor="#4ebfae" />
                <stop offset="1" stopColor="#00aeca" />
              </linearGradient>
              <path
                d={blobs.svgPath({ seed: 1, extraPoints: 5, randomness: 7, size: 300 })}
                fill="url(#gradient)"
              />
            </svg>
            <Img
              loading="lazy"
              objectFit="contain"
              alt="grid-with-icons"
              title="grid-with-icons"
              fluid={props.image.childImageSharp.fluid}
            />
          </div>
        )
      ) : null}

      {(props.gridWithIconsAndImageList || [])[1] ? (
        <Item {...props.gridWithIconsAndImageList[1]} />
      ) : null}
    </div>

    <div className={styles.grid}>
      {(props.gridWithIconsAndImageList || [])
        .slice(2)
        .filter(Boolean)
        .map((item, i) => (
          <Item key={i} {...item} />
        ))}
    </div>
  </Container>
)

export default GridWithIconsAndImage
