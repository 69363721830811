import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import TitleBlock from "components/Blocks/TitleBlock"
import Container from "components/Container"
import styles from "./GridWithImageAndList.module.css"

export const GridWithImageAndList = (props) => (
  <Container className={styles.root}>
    <TitleBlock isColored>{props.title}</TitleBlock>
    <div className={styles.wrap}>
      <ul className={styles.list}>
        {(props.gridWithImageAndList || []).filter(Boolean).map((item, i) => (
          <li key={i} className={styles.item}>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </li>
        ))}
      </ul>

      {props.image && props.image.extension === "svg" ? (
        <div className={styles.image}>
          <img alt={props.title} title={props.title} src={props.image.publicURL} loading="lazy" width="400" height="400"/>
        </div>
      ) : props.image && props.image.childImageSharp ? (
        <div className={styles.image}>
          <Img
            loading="lazy"
            objectFit="contain"
            alt={props.title}
            title={props.title}
            fluid={props.image.childImageSharp.fluid}
          />
        </div>
      ) : null}
    </div>
  </Container>
)

export default GridWithImageAndList
