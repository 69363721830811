import React from "react"
import TitleBlock from "components/Blocks/TitleBlock"
import Container from "components/Container"
import styles from "./BigList.module.css"

export const BigList = (props) => (
  <Container className={styles.root}>
    <TitleBlock>{props.title}</TitleBlock>
    {(props.bigListList || []).length > 0 ? (
      <ul className={styles.list}>
        {props.bigListList.filter(Boolean).map((item, i) => (
          <li key={i} className={styles.item}>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </li>
        ))}
      </ul>
    ) : null}
  </Container>
)

export default BigList
