import React from "react"
import marked from "marked"
import Img from "gatsby-image/withIEPolyfill"
import Container from "components/Container"
import styles from "./ImageAndList.module.css"

const renderer = new marked.Renderer()
renderer.paragraph = (text) => text + "\n"

export const ImageAndList = (props) => (
  <div className={styles.root}>
    <Container className={styles.wrap}>
      <div className={styles.image}>
        {props.image && props.image.extension === "svg" ? (
          <img alt="list" title="image" src={props.image.publicURL} loading="lazy" width="200" height="200"/>
        ) : props.image && props.image.childImageSharp ? (
          <Img
            loading="lazy"
            alt="image"
            title="image"
            objectFit="fit"
            fluid={props.image.childImageSharp.fluid}
          />
        ) : null}
      </div>
      <ul className={styles.list}>
        {(props.imageAndListList || []).filter(Boolean).map((item, i) => (
          <li
            key={i}
            className={styles.item}
            dangerouslySetInnerHTML={{ __html: marked(item || "", { renderer }) }}
          />
        ))}
      </ul>
    </Container>
  </div>
)

export default ImageAndList
