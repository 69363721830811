import React from "react"
import marked from "marked"
import Img from "gatsby-image/withIEPolyfill"
import Container from "components/Container"
import TitleBlock from "./TitleBlock"
import styles from "./Cards.module.css"

export const Cards = (props) => (
  <Container className={styles.root}>
    {props.title ? <TitleBlock isColored>{props.title}</TitleBlock> : null}
    {props.description ? (
      <div
        className={styles.desc}
        dangerouslySetInnerHTML={{ __html: marked(props.description || "") }}
      />
    ) : null}

    {(props.cards || []).length > 0 ? (
      <ul className={styles.list}>
        {props.cards.map((card, i) => (
          <li key={i} className={styles.item}>
            <h3>
              <span>{card.title}</span>
              {card.icon && card.icon.extension === "svg" ? (
                <img alt={card.title} title={card.title} src={card.icon.publicURL} loading="lazy"/>
              ) : card.icon && card.icon.childImageSharp ? (
                <Img
                  loading="lazy"
                  alt={card.title}
                  title={card.title}
                  objectFit="contain"
                  fluid={card.icon.childImageSharp.fluid}
                />
              ) : null}
            </h3>

            <div dangerouslySetInnerHTML={{ __html: marked(card.text || "") }} />
          </li>
        ))}
      </ul>
    ) : null}
  </Container>
)

export default Cards
