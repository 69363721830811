import React from "react"
import TitleBlock from "./TitleBlock"
import Container from "components/Container"
import styles from "./TableData.module.css"

export const TableData = (props) => (
  <Container className={styles.root}>
    <TitleBlock>{props.title}</TitleBlock>
    <p>{props.description}</p>
    <div className={styles.tableData}>
      <table className={props.className}>
        {(props.tableHead || []).length > 0 ? (
          <thead>
            <tr>
              {props.tableHead.filter(Boolean).map((item, i) => (
                <th key={i} className={styles.thLabel}>
                  <span>{item.label}</span>
                </th>
              ))}
            </tr>
          </thead>
        ) : null}
        {(props.tableRow || []).length > 0 ? (
          <tbody>
            {props.tableRow.filter(Boolean).map((item, i) => (
              <tr key={i}>
                {item.row.filter(Boolean).map((row, i) => (
                  <td key={i} className={styles.tdValue}>
                    <span>
                      {row.value}
                      {(row.icon) ? (
                        <i></i>
                      ) : null}
                    </span>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        ) : null}
      </table>
    </div>
  </Container>
)

export default TableData
