import Cards from "./Cards"
import BigList from "./BigList"
import ImagesGrid from "./ImagesGrid"
import TwoCollGrid from "./TwoCollGrid"
import ImageAndList from "./ImageAndList"
import GridWithImage from "./GridWithImage"
import GridWithIcons from "./GridWithIcons"
import GradientedGridList from "./GradientedGridList"
import GridWithLeftedImage from "./GridWithLeftedImage"
import IconsWithBackground from "./IconsWithBackground"
import GridWithImageAndList from "./GridWithImageAndList"
import FullWidthHighlighted from "./FullWidthHighlighted"
import BigListWithBackgrounds from "./BigListWithBackgrounds"
import ListWithImageAndDescription from "./ListWithImageAndDescription"
import GridWithIconsAndImage from "./GridWithIconsAndImage"
import BlobIcons from "./BlobIcons"
import IconsGrid from "./IconsGrid"
import FullWidthCards from "./FullWidthCards"
import ColoredBlock from "./ColoredBlock"
import ChessGrid from "./ChessGrid"
import IconsPath from "./IconsPath"
import FullWidthImageGrid from './FullWidthImageGrid'
import TextWithImage from './TextWithImage'
import TextWithCards from './TextWithCards'
import IconsGridWithBackground from './IconsGridWithBackground'
import DoubleTitleWithImages from './DoubleTitleWithImages'
import TextWithImageAndCTA from './TextWithImageAndCTA'
import BlogPostsSection from './BlogPostsSection'
import CaseStudyPostsSection from './CaseStudyPostsSection'
import pageBlobIconList from './PageBlobIconList'
import Testimonial from './Testimonial'
import TableData from './TableData'
import WeWorkWithSection from "./WeWorkWithSection"
import PpcSiteDataList from './PpcSiteDataList'
import PpcImageWithContent from './PpcImageWithContent'
import PpcContentGrid from './PpcContentGrid'
import PpcTestimonial from './PpcTestimonial'
import PpcMiddleCTA from './PpcMiddleCTA'
import PpcContentSlider from './PpcContentSlider'
import PpcFornovaLayout from './PpcFornovaLayout'
import PpcContentWithBigImage from './PpcContentWithBigImage'
import TextWithImageAndIcons from './TextWithImageAndIcons'
import ContentWithList from './ContentWithList'
import CaseStudySliderSection from './CaseStudySliderSection'
import ContentSlider from './ContentSlider'
import ContentWithGraphAndTable from './ContentWithGraphAndTable'
import ContentWithImageAndList from './ContentWithImageAndList'
import PpcSpeakingSession from './PpcSpeakingSession'

export default {
  iconsPath: IconsPath,
  chessGrid: ChessGrid,
  fullWidthCards: FullWidthCards,
  colored: ColoredBlock,
  cards: Cards,
  bigList: BigList,
  blobIcons: BlobIcons,
  iconsGrid: IconsGrid,
  twoCollGrid: TwoCollGrid,
  imageAndList: ImageAndList,
  gridWithImage: GridWithImage,
  gridWithIcons: GridWithIcons,
  gridWithLeftedImage: GridWithLeftedImage,
  fullWidthHighlighted: FullWidthHighlighted,
  bigListWithBackgrounds: BigListWithBackgrounds,
  gridWithImageAndList: GridWithImageAndList,
  gradientedGridList: GradientedGridList,
  iconsWithBackgrounds: IconsWithBackground,
  imagesGrid: ImagesGrid,
  listWithImageAndDescription: ListWithImageAndDescription,
  gridWithIconsAndImage: GridWithIconsAndImage,
  fullWidthImageGrid: FullWidthImageGrid,
  textWithImage: TextWithImage,
  textWithCards: TextWithCards,
  iconsGridWithBackground: IconsGridWithBackground,
  doubleTitleWithImages: DoubleTitleWithImages,
  textWithImageAndCTA: TextWithImageAndCTA,
  blogPostsSection: BlogPostsSection,
  caseStudyPostsSection: CaseStudyPostsSection,
  pageBlobIconList: pageBlobIconList,
  testimonial: Testimonial,
  tableData: TableData,
  weWorkWithSection: WeWorkWithSection,
  ppcSiteDataList: PpcSiteDataList,
  ppcImageWithContent: PpcImageWithContent,
  ppcContentGrid: PpcContentGrid,
  ppcTestimonial: PpcTestimonial,
  ppcMiddleCTA: PpcMiddleCTA,
  ppcContentSlider: PpcContentSlider,
  ppcFornovaLayout: PpcFornovaLayout,
  ppcContentWithBigImage: PpcContentWithBigImage,
  textWithImageAndIcons: TextWithImageAndIcons,
  contentWithList: ContentWithList,
  caseStudySliderSection: CaseStudySliderSection,
  contentSlider: ContentSlider,
  contentWithGraphAndTable: ContentWithGraphAndTable,
  contentWithImageAndList: ContentWithImageAndList,
  ppcSpeakingSession: PpcSpeakingSession,
}
