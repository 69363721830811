import React from "react"
import TitleBlock from "./TitleBlock"
import Container from "components/Container"
import Img from "gatsby-image/withIEPolyfill"
import styles from "./ImagesGrid.module.css"

export const ImagesGrid = (props) => (
  <Container className={styles.root}>
    <TitleBlock>{props.title}</TitleBlock>
    <div className={styles.grid}>
      {(props.imagesGrid || []).filter(Boolean).map(({ image }, i) => (
        <div className={styles.img} key={i}>
          {image ? (
            <Img
              loading="lazy"
              objectFit="contain"
              alt={props.imageAltText ? props.imageAltText : props.title}
              title={props.title}
              fluid={image.childImageSharp.fluid}
            />
          ) : null}
        </div>
      ))}
    </div>
  </Container>
)

export default ImagesGrid
