import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import Container from "components/Container"
import TitleBlock from "./TitleBlock"
import styles from "./GridWithLeftedImage.module.css"

const Item = (props) => (
  <div className={styles.item}>
    <h3>{props.title}</h3>
    <p>{props.text}</p>
  </div>
)

export const GridWithLeftedImage = (props) => (
  <div className={styles.root}>
    <Container>
      <TitleBlock>{props.title}</TitleBlock>

      <div className={styles.wrap}>
        {props.image && props.image.extension === "svg" ? (
          <div className={styles.image}>
            <img alt={props.title} title={props.title} src={props.image.publicURL} loading="lazy" height="300" width="300"/>
          </div>
        ) : props.image && props.image.childImageSharp ? (
          <div className={styles.image}>
            <Img
              loading="lazy"
              alt={props.title}
              title={props.title}
              objectFit="contain"
              fluid={props.image.childImageSharp.fluid}
            />
          </div>
        ) : null}
        {(props.gridWithLeftedImage || []).length > 0 ? (
          <div>
            {props.gridWithLeftedImage.slice(0, 2).map((item, i) => (
              <Item {...item} key={i} />
            ))}
          </div>
        ) : null}
      </div>
      <div className={styles.grid}>
        {(props.gridWithLeftedImage || []).slice(2).map((item, i) => (
          <Item {...item} key={i} />
        ))}
      </div>
    </Container>
  </div>
)

export default GridWithLeftedImage
