import React from "react"
import { Link } from "gatsby"
import cx from "classnames"
import marked from "marked"
import Container from "components/Container"
import Img from "gatsby-image/withIEPolyfill"
import TitleBlock from "./TitleBlock"
import styles from "./PpcFornovaLayout.module.css"

export const PpcFornovaLayout = (props) => (
  <div className={cx({ [styles.root]: styles.root, }, props.className, {
    [styles.gray]: props.bgColor === 'isGray', [styles.gradient]: props.bgColor === 'isGradient', [styles.noPaddingTop]: props.paddingTop === 'noPadding',
  })}>
    <Container>
        <div className={styles.rootWrap}>
          {props.title ? (
            <TitleBlock isLarger>{props.title}</TitleBlock>
          ) : null}
          <div className={styles.text} dangerouslySetInnerHTML={{ __html: marked(props.text || "") }} />
          <div className={styles.content}>
            {props.iconsGridList ? (
              <div className={styles.iconList}>
                {(props.iconsGridList || []).filter(Boolean).map((item, i) => (
                  <div className={styles.iconItem} key={i}>
                    {item.icon && item.icon.extension === "svg" ? (
                      <div className={styles.icon}>
                        <img alt={item.label} title={item.label} src={item.icon.publicURL} loading="lazy" width="65" height="65"/>
                      </div>
                    ) : item.icon && item.icon.childImageSharp ? (
                      <Img
                          loading="lazy"
                          className={styles.icon}
                          objectFit="contain"
                          alt={item.label}
                          title={item.label}
                          fluid={item.icon.childImageSharp.fluid}
                      />
                    ) : null}
                    {item.description ? <p>{item.description}</p> : null}
                  </div>
                ))}
              </div>
            ) : null}
            <div className={styles.contentImage}>
              {props.image.extension === "svg" ? (
                <div className={styles.image}>
                  <img alt={props.name} title={props.name} src={props.image.publicURL} loading="lazy" width="500" height="300"/>
                </div>
              ) : props.image.childImageSharp ? (
                <Img
                  loading="lazy"
                  className={styles.image}
                  objectFit="contain"
                  alt={props.name}
                  title={props.name}
                  fluid={props.image.childImageSharp.fluid}
                />
              ) : null}
            </div>
          </div>
          {props.buttonText ? (
            <Link className={styles.button} to={`${props.buttonUrl}`}>{props.buttonText}</Link>
          ) : null}
        </div>
    </Container>
  </div>
)

export default PpcFornovaLayout
