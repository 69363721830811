import React from "react"
import marked from "marked"
import Container from "components/Container"
import TitleBlock from "./TitleBlock"
import styles from "./TwoCollGrid.module.css"

export const TwoCollGrid = (props) => (
  <div className={styles.root}>
    <Container>
      <TitleBlock isWhite>{props.title}</TitleBlock>
      <div className={styles.grid}>
        {(props.twoCollGridList || []).filter(Boolean).map((item, i) => (
          <div className={styles.item} key={i}>
            <h4>{item.title}</h4>
            <div dangerouslySetInnerHTML={{ __html: marked(item.text || "") }} />
          </div>
        ))}
      </div>
    </Container>
  </div>
)

export default TwoCollGrid
