import React from "react"
import cx from "classnames"
import Img from "gatsby-image/withIEPolyfill"
import Container from "components/Container"
import styles from "./PpcSiteDataList.module.css"

export const PpcSiteDataList = (props) => (
  <div className={cx(props.className, {
    [styles.gray]: props.bgColor === 'isGray', [styles.gradient]: props.bgColor === 'isGradient'
  })}>
    <Container className={styles.root}>
      {(props.ppcSiteDataList || []).length > 0 ? (
        <div className={styles.list}>
          <ul className={styles.listInner}>
            {props.ppcSiteDataList.filter(Boolean).map((item, i) => (
              <li key={i} className={styles.item}>
                <div className={styles.listItemWrapper}>
                  {item.image ? (
                    <div className={styles.image}>
                      {item.image.extension === "svg" ? (
                        <img alt={item.title} title={item.title} src={item.image.publicURL} height="60" width="60" loading="lazy"/>
                      ) : item.image.childImageSharp ? (
                        <Img
                          loading="lazy"
                          objectFit="contain"
                          alt={item.title}
                          title={item.title}
                          fluid={item.image.childImageSharp.fluid}
                        />
                      ) : null}
                    </div>
                  ) : (
                    <>
                      <div className={styles.icon}>
                        {item.icon && item.icon.extension === "svg" ? (
                          <img alt={item.title} title={item.title} src={item.icon.publicURL} height="40" width="40" loading="lazy"/>
                        ) : item.icon && item.icon.childImageSharp ? (
                          <Img
                            loading="lazy"
                            objectFit="contain"
                            alt={item.title}
                            title={item.title}
                            fluid={item.icon.childImageSharp.fluid}
                          />
                        ) : null}
                        <p>{item.title}</p>
                      </div>
                      <p>{item.description}</p>
                    </>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </Container>
  </div>
)

export default PpcSiteDataList
