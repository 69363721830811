import React from "react"
import { Link } from "gatsby"
import cx from "classnames"
import marked from "marked"
import Container from "components/Container"
import Img from "gatsby-image/withIEPolyfill"
import TitleBlock from "./TitleBlock"
import styles from "./PpcContentWithBigImage.module.css"

export const PpcContentWithBigImage = (props) => (
  <div className={cx({ [styles.root]: styles.root, }, props.className, {
    [styles.gray]: props.bgColor === 'isGray',
    [styles.gradient]: props.bgColor === 'isGradient',
    [styles.noPaddingTop]: props.paddingTop === 'noPadding',
    [styles.normal]: props.layout === 'normal',
  })}>
    <Container>
        <div className={styles.rootWrap}>
          <div className={styles.content}>
            <div className={styles.contentWrap}>
              {props.title ? (
                <TitleBlock isLarger><span dangerouslySetInnerHTML={{ __html: props.title || ""}} /></TitleBlock>
              ) : null}
              {props.subTitle ? <div className={styles.subTitle} dangerouslySetInnerHTML={{ __html: marked(props.subTitle || "") }} /> : null}
              <div className={styles.text} dangerouslySetInnerHTML={{ __html: marked(props.text || "") }} />
              {props.buttonText ? (
                <Link className={styles.button} to={`${props.buttonUrl}`}>{props.buttonText}</Link>
              ) : null}
              {props.image && props.layout ? (
                <div className={styles.image}>
                  {props.image.extension === "svg" ? (
                    <img alt={props.name} title={props.name} src={props.image.publicURL} loading="lazy" width="1920" height="500" />
                  ) : props.image.childImageSharp ? (
                    <Img
                      loading="lazy"
                      className={styles.imageMain}
                      objectFit="contain"
                      alt={props.name}
                      title={props.name}
                      fluid={props.image.childImageSharp.fluid}
                    />
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
    </Container>
    {props.image && !props.layout ? (
      <div className={styles.image}>
        {props.image.extension === "svg" ? (
          <img alt={props.name} title={props.name} src={props.image.publicURL} loading="lazy" width="1920" height="500" />
        ) : props.image.childImageSharp ? (
          <Img
            loading="lazy"
            className={styles.imageMain}
            objectFit="contain"
            alt={props.name}
            title={props.name}
            fluid={props.image.childImageSharp.fluid}
          />
        ) : null}
      </div>
    ) : null}
  </div>
)

export default PpcContentWithBigImage
