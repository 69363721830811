import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import Container from "components/Container"
import TitleBlock from "./TitleBlock"
import cx from "classnames"
import marked from "marked"
import styles from "./PpcContentSlider.module.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function CustomArrowPrev(props) {
  const { className, style, onClick } = props;
  return (
    // eslint-disable-next-line
    <div
      className={cx(className, styles.slickArrow, styles.slickArrowPrev)}
      style={{...style}}
      onClick={onClick}
    />
  );
}

function CustomArrowNext(props) {
  const { className, style, onClick } = props;
  return (
    // eslint-disable-next-line
    <div
      className={cx(className, styles.slickArrow, styles.slickArrowNext)}
      style={{...style}}
      onClick={onClick}
    />
  );
}

let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    prevArrow: <CustomArrowPrev />,
    nextArrow: <CustomArrowNext />,
    dotsClass: cx(styles.slickDots, 'slick-dots'),
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                adaptiveHeight: true,
                arrows: false,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                adaptiveHeight: true,
                arrows: false,
            }
        }
    ]
}

export const PpcContentSlider = (props) => {
  
  settings.slidesToShow = props.layout === 'threeCol' ? 3 : 2

  return (
    <div className={cx({ [styles.root]: styles.root, }, props.className, {
      [styles.gray]: props.bgColor === 'isGray',
      [styles.gradient]: props.bgColor === 'isGradient',
      [styles.threeCol]: props.layout === 'threeCol'
    })}>
      <Container>
        <div className={styles.content}>
          {props.subTitle ? <div className={styles.subtitle} dangerouslySetInnerHTML={{ __html: marked(props.subTitle || "") }} /> : null}
          <TitleBlock>{props.title}</TitleBlock>
          <div className={styles.text} dangerouslySetInnerHTML={{ __html: marked(props.text || "") }} />
          <div className={styles.contentSlider}>
            {props.iconsGridList ? (
              <Slider {...settings} className={styles.contentSliderMain}>
                {(props.iconsGridList || []).filter(Boolean).map((item, i) => (
                  <div className={styles.item} key={i}>
                    <div className={styles.itemWrap}>
                      <div className={styles.itemTitle}>
                        {item.icon && item.icon.extension === "svg" ? (
                          <div className={styles.icon}>
                            <img alt={item.title} title={item.title} src={item.icon.publicURL} loading="lazy" height="59" width="59"/>
                          </div>
                        ) : item.icon && item.icon.childImageSharp ? (
                          <div className={styles.icon}>
                            <Img
                                loading="lazy"
                                objectFit="contain"
                                alt={item.title}
                                title={item.title}
                                fluid={item.icon.childImageSharp.fluid}
                            />
                          </div>
                        ) : null}
                        <h3>{item.title}</h3>
                      </div>
                      {item.description ? <div dangerouslySetInnerHTML={{ __html: marked(item.description || "") }} /> : null}
                    </div>
                  </div>
                ))}
              </Slider>
            ) : null}
          </div>
          {props.buttonText ? (
            <Link className={styles.button} to={`${props.buttonUrl}`}>{props.buttonText}</Link>
          ) : null}
        </div>
      </Container>
    </div>
  )
}

export default PpcContentSlider
