import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import TitleBlock from "./TitleBlock"
import Container from "components/Container"
import * as blobs from "blobs/v2"
import styles from "./IconsWithBackground.module.css"

export const Icon = (props) => {
  const blob = blobs.svgPath({ seed: props.index, extraPoints: 5, randomness: 7, size: 300 })

  return (
    <div className={styles.item}>
      {props.icon ? (
        props.icon.extension === "svg" ? (
          <div className={styles.image}>
            <img alt={props.title} title={props.title} src={props.icon.publicURL} loading="lazy" width="300" height="300"/>
          </div>
        ) : (
          <div className={styles.image}>
            <svg viewBox="0 0 300 300">
              <linearGradient id="gradient" gradientUnits="userSpaceOnUse">
                <stop stopColor="#4ebfae" />
                <stop offset="1" stopColor="#00aeca" />
              </linearGradient>
              <path d={blob} fill="url(#gradient)" />
            </svg>
            <Img
              loading="lazy"
              objectFit="contain"
              alt={props.title}
              title={props.title}
              fluid={props.icon.childImageSharp.fluid}
            />
          </div>
        )
      ) : null}
      <h3>{props.title}</h3>
    </div>
  )
}

export const IconsWithBackground = (props) => (
  <Container className={styles.root}>
    <TitleBlock>{props.title}</TitleBlock>
    <div className={styles.wrap}>
      {(props.iconsWithBackgroundsList || []).filter(Boolean).map((icon, i) => (
        <Icon key={i} {...icon} index={i} />
      ))}
    </div>
  </Container>
)

export default IconsWithBackground
