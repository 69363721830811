import React from "react"
import TitleBlock from "components/Blocks/TitleBlock"
import Container from "components/Container"
import styles from "./BigListWithBackgrounds.module.css"

export const BigListWithBackgrounds = (props) => (
  <div className={styles.root}>
    <Container>
      <TitleBlock>{props.title}</TitleBlock>
      {(props.bigListWithBackgroundsList || []).length > 0 ? (
        <ul className={styles.list}>
          {props.bigListWithBackgroundsList.filter(Boolean).map((item, i) => (
            <li key={i} className={styles.item}>
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </li>
          ))}
        </ul>
      ) : null}
    </Container>
  </div>
)

export default BigListWithBackgrounds
