import React from "react"
import Container from "components/Container"
import Img from "gatsby-image/withIEPolyfill"
import styles from "./FullWidthImageGrid.module.css"

export const FullWidthImageGrid = (props) => (
  <Container className={styles.root}>
    <div className={styles.grid}>
        <div className={styles.titlecontainer}>
            <h2 className={styles.title}>We Work With</h2>
        </div>
        <div className={styles.logocontainer}>
            {(props.fullWidthImageGrid || []).filter(Boolean).map(({ image, link }, i) => (
              <div>
                {link ? (
                  <a className={styles.img}
                    key={i}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                  {image && image.extension === "svg" ? (
                    <img className={styles.gridimage} alt={props.name} title={props.name} src={image.publicURL} width="150" heigh="35" loading="lazy"/>
                  ) : image ? (
                    <Img 
                    loading="lazy"
                    className={styles.gridimage}
                    objectFit="contain"
                    alt={props.title}
                    title={props.title}
                    fluid={image.childImageSharp.fluid}
                    />
                  ) : null}
                  </a>
                ) : (
                  <div className={styles.img} key={i}>
                  {image && image.extension === "svg" ? (
                    <img className={styles.gridimage} alt={props.name} title={props.name} src={image.publicURL} width="150" heigh="35" loading="lazy"/>
                  ) : image ? (
                    <Img 
                    loading="lazy"
                    className={styles.gridimage}
                    objectFit="contain"
                    alt={props.title}
                    title={props.title}
                    fluid={image.childImageSharp.fluid}
                    />
                  ) : null}
                  </div>
                )}
              </div>
            ))}
      </div>
    </div>
  </Container>
)

export default FullWidthImageGrid
