import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import Container from "components/Container"
import TitleBlock from "./TitleBlock"
import cx from "classnames"
import styles from "./ContentSlider.module.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function CustomArrowPrev(props) {
  const { className, style, onClick } = props;
  return (
    // eslint-disable-next-line
    <div
      className={cx(className, styles.slickArrow, styles.slickArrowPrev)}
      style={{...style}}
      onClick={onClick}
    />
  );
}

function CustomArrowNext(props) {
  const { className, style, onClick } = props;
  return (
    // eslint-disable-next-line
    <div
      className={cx(className, styles.slickArrow, styles.slickArrowNext)}
      style={{...style}}
      onClick={onClick}
    />
  );
}

const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomArrowPrev />,
    nextArrow: <CustomArrowNext />,
    dotsClass: cx(styles.slickDots, 'slick-dots'),
    adaptiveHeight: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                arrows: false
            }
        }
    ]
}

export const ContentSlider = (props) => (
  <div className={cx({ [styles.root]: styles.root, }, props.className, {
    [styles.gray]: props.bgColor === 'isGray', [styles.gradient]: props.bgColor === 'isGradient'
  })}>
    <Container>
      <TitleBlock>{props.title}</TitleBlock>
      {props.description ? <p className={styles.maindesc}>{props.description}</p> : null}
      {props.contentSlider ? (
        <div className={styles.sliderWrap}>
          <Slider {...settings} className={styles.contentSliderMain}>
            {(props.contentSlider || []).filter(Boolean).map((item, i) => (
              <Link to={`${item.link}`} className={styles.contentSliderWrap}>
                {item.image.extension === "svg" ? (
                  <div className={styles.image}>
                    <img alt={item.name} title={item.name} src={item.image.publicURL} width="150" height="33" loading="lazy"/>
                  </div>
                ) : item.image.childImageSharp ? (
                  <Img
                    loading="lazy"
                    className={styles.image}
                    objectFit="contain"
                    alt={item.name}
                    title={item.name}
                    fluid={item.image.childImageSharp.fluid}
                  />
                ) : null}
                <div className={styles.details}>
                  {item.title ? <h3>{item.title}</h3> : null}
                  {item.description ? <p className={styles.desc}>{item.description}</p> : null}
                </div>
              </Link>
            ))}
          </Slider>
        </div>
      ) : null}
      {props.buttonText ? (
        <Link className={styles.button} to={`${props.buttonUrl}`}>{props.buttonText}</Link>
      ) : null}
    </Container>
  </div>
)

export default ContentSlider
