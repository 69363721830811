import React from "react"
import marked from "marked"
import Img from "gatsby-image/withIEPolyfill"
import Container from "components/Container"
import TitleBlock from "./TitleBlock"
import * as blobs from "blobs/v2"
import styles from "./FullWidthCards.module.css"

export const FullWidthCards = (props) => (
  <Container className={styles.root}>
    {props.title ? <TitleBlock>{props.title}</TitleBlock> : null}
    {props.description ? <p className={styles.desc}>{props.description}</p> : null}
    <div className={styles.grid}>
      {(props.fullWidthCardsList || []).filter(Boolean).map((item, i) => (
        <div key={i} className={styles.item}>
          {item.icon ? (
            <div className={styles.icon}>
              <svg viewBox="0 0 240 240">
                <linearGradient id="gradient" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#4ebfae" />
                  <stop offset="1" stopColor="#00aeca" />
                </linearGradient>
                <path
                  d={blobs.svgPath({ seed: i, extraPoints: 4, randomness: 3, size: 240 })}
                  fill="url(#gradient)"
                />
              </svg>
              {item.icon.extension === "svg" ? (
                <img alt={item.imageAltText ? item.imageAltText : item.title} title={item.title} src={item.icon.publicURL} loading="lazy" height="140" width="140" />
              ) : item.icon.childImageSharp ? (
                <Img
                  loading="lazy"
                  objectFit="cover"
                  alt={item.imageAltText ? item.imageAltText : item.title}
                  title={item.title}
                  fluid={item.icon.childImageSharp.fluid}
                />
              ) : null}
            </div>
          ) : null}
          <div className={styles.info}>
            <h4>{item.title}</h4>
            <div dangerouslySetInnerHTML={{ __html: marked(item.description || "") }} />
          </div>
        </div>
      ))}
    </div>
  </Container>
)

export default FullWidthCards
