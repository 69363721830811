import React from "react"
import marked from "marked"
import { Link } from "gatsby"
import Container from "components/Container"
import Img from "gatsby-image/withIEPolyfill"
import TitleBlock from "./TitleBlock"
import cx from "classnames"
import styles from "./TextWithCards.module.css"



export const TextWithCards = (props) => (
  
  <div className={cx({ [styles.root]: styles.root, }, props.className, {
    [styles.gray]: props.bgColor === 'isGray', [styles.gradient]: props.bgColor === 'isGradient', [styles.noPaddingTop]: props.paddingTop === 'noPadding', [styles.smallPaddingTop]: props.paddingTop === 'smallPaddingTop'
  })}>
    <Container>
    <TitleBlock isLarger><span dangerouslySetInnerHTML={{ __html: props.title || ""}} /></TitleBlock>
        {props.subTitle ? <div className={styles.subTitle} dangerouslySetInnerHTML={{ __html: marked(props.subTitle || "") }} /> : null}
        {props.description ? <div className={styles.desc} dangerouslySetInnerHTML={{ __html: marked(props.description || "") }} /> : null}
        {props.buttonText && props.buttonPosition === 'beforeGrid' ? (
        <Link className={`${styles.button} ${styles.reverseButtonMargin}`} to={`${props.buttonUrl}`}>{props.buttonText}</Link>
      ) : null}
        <div className={cx({ [styles.cards]: styles.cards, }, props.className, {
                  [styles.twoColumn]: props.layout === 'twoColIconOnSide', [styles.arrowsBetweenCards]: props.arrowsBetweenCards === 'yes',
                })}>
          {(props.textWithCards || []).filter(Boolean).map((item, i) => (
              <div className={cx({ [styles.card]: styles.card, }, props.className, {
                [styles.boxShadow]: props.bgColor !== 'isGradient', })}>
                  {item.image ? (
                  <div className={styles.img} key={i}>
                    {item.image.extension === "svg" ? (
                      <img alt={item.image.name} title={item.image.name} src={item.image.publicURL} loading="lazy" width="85" height="85"/>
                    ) : item.image.childImageSharp ? (
                      <Img 
                      loading="lazy"
                      className={styles.cardimage}
                      objectFit="contain"
                      alt={item.image.title}
                      title={item.image.title}
                      fluid={item.image.childImageSharp.fluid}
                      />
                    ): null}
                  </div>
                  ) : null}
                  {item.preTitle ? (
                    <div>
                      <p className={styles.preTitle}>{item.preTitle}</p>
                      <h3 className={styles.cardTitle} dangerouslySetInnerHTML={{ __html: item.title || "" }} />
                    </div>
                    ) : <h3 className={styles.cardTitle} dangerouslySetInnerHTML={{ __html: item.title || "" }} />}
                  {item.text ? <div className={styles.desc} dangerouslySetInnerHTML={{ __html: marked(item.text || "") }} /> : null}
              </div>
          ))}
        </div>
        {props.secondTitle ? (
          <div class={styles.secondContainer}>
              <h3 className={styles.titleSmall}>{props.secondTitle}</h3>
            {props.image ? (
            <div className={styles.img}>
                {props.image.extension === "svg" ? (
                <img alt={props.image.name} title={props.image.name} src={props.image.publicURL} loading="lazy" width="85" height="85"/>
                ) : props.image.childImageSharp ? (
                <Img
                    loading="lazy"
                    objectFit="fit"
                    alt={props.image.name}
                    title={props.image.name}
                    fluid={props.image.childImageSharp.fluid}
                />
                ) : null}
            </div>
            ) : null}
          </div>
         ) : null}
        {props.thirdTitle ? (
          <div>
            <h3 className={styles.titleSmall}>{props.thirdTitle}</h3>
            <div className={styles.logocontainer}>
              {(props.imagesGrid || []).filter(Boolean).map(({ image }, i) => (
                  <div className={styles.img} key={i}>
                  {image && image.extension === "svg" ? (
                  <div className={styles}>
                  <img alt={image.title} title={image.title} src={image.publicURL} loading="lazy" width="85" height="85"/>
                  </div>
              ) : image && image.childImageSharp ? (
                  <div>
                  <Img
                      loading="lazy"
                      objectFit="contain"
                      alt={image.title}
                      title={image.title}
                      fluid={image.childImageSharp.fluid}
                  />
                  </div>
              ) : null}
                  </div>
              ))}
            </div>
          </div>
        ) : null}
      {props.buttonText && props.buttonPosition !== 'beforeGrid' ? (
        <Link className={styles.button} to={`${props.buttonUrl}`}>{props.buttonText}</Link>
      ) : null}
    </Container>
  </div>
)

export default TextWithCards
