import React from "react"
import cx from "classnames"
import Img from "gatsby-image/withIEPolyfill"
import Container from "components/Container"
import styles from "./ColoredBlock.module.css"

export const ColoredBlock = (props) => (
  <div className={cx({ [styles.colored]: styles.colored, }, props.className, {
    [styles.isGradient]: props.bgColor === 'isGradient'
  })}>
    <Container className={styles.root}>
      <div className={styles.wrapper}>
        {props.media1 ? (
          <div className={styles.image}>
            <Img
              loading="lazy"
              objectFit="cover"
              alt={props.title}
              title={props.title}
              fluid={props.media1.childImageSharp.fluid}
            />
          </div>
        ) : null}
        <h2 className={styles.title}>{props.title}</h2>
        <p className={styles.desc}>{props.description}</p>
      </div>
    </Container>
  </div>
)

export default ColoredBlock
