import React from "react"
import marked from "marked"
import Container from "components/Container"
import Img from "gatsby-image/withIEPolyfill"
import { Link } from "gatsby"
import TitleBlock from "./TitleBlock"
import cx from "classnames"
import styles from "./DoubleTitleWithImages.module.css"

export const DoubleTitleWithImages = (props) => (
    <div className={cx({
        [styles.gray]: props.bgColor === 'isGray',
        [styles.gradient]: props.bgColor === 'isGradient',
        [styles.noPaddingTop]: props.paddingTop === 'noPadding',
        [styles.smallPaddingTop]: props.paddingTop === 'smallPaddingTop',
        [styles.twoColImages]: props.layout === 'twoColImages'
      }, props.className, )}>
        <Container className={styles.root}>
            <div className={styles.titlecontainer}>
                {props.layout === 'noHeading' ? (
                    <p className={styles.title}><span>{props.title}</span>{props.subTitle}</p>
                ) : (
                    <TitleBlock isLarger className={styles.title}><span>{props.title}</span>{props.subTitle}</TitleBlock>
                )}
                {props.text ? <div className={styles.text} dangerouslySetInnerHTML={{ __html: marked(props.text || "") }} /> : null}
            </div>
            <div className={styles.logocontainer}>
                {(props.imagesGrid || []).filter(Boolean).map(({ image, link, imageAltText }, i) => (
                    <div className={styles.img} key={i}>
                    {image && image.extension === "svg" ? (
                        link ? (
                            <Link to={link} rel="noopener noreferrer">
                                    <img alt={imageAltText ? imageAltText : image.title} title={image.title} src={image.publicURL} loading="lazy" width="150" height="150"/>
                            </Link>
                        ) : <img alt={imageAltText ? imageAltText : image.title} title={image.title} src={image.publicURL} />
                ) : image && image.childImageSharp ? (
                    link ? (
                            <Link to={link} rel="noopener noreferrer">
                                <Img
                                    loading="lazy"
                                    objectFit="contain"
                                    alt={imageAltText ? imageAltText : image.title}
                                    title={image.title}
                                    fluid={image.childImageSharp.fluid}
                                />
                        </Link>
                    ) :
                    <Img
                        loading="lazy"
                        objectFit="contain"
                        alt={imageAltText ? imageAltText : image.title}
                        title={image.title}
                        fluid={image.childImageSharp.fluid}
                    />
                ) : null}
                    </div>
                ))}
            </div>
            {props.buttonText ? (
                <Link className={styles.button} to={`${props.buttonUrl}`}>{props.buttonText}</Link>
              ) : null}
        </Container>
    </div>
)

export default DoubleTitleWithImages
