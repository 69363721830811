import React from "react"
import marked from "marked"
import { Link } from "gatsby"
import Container from "components/Container"
import cx from "classnames"
import Img from "gatsby-image/withIEPolyfill"
import TitleBlock from "./TitleBlock"
import HubspotForm from "components/HubspotForm"
import styles from "./TextWithImageAndIcons.module.css"

export const TextWithImageAndIcons = (props) => (
  <div className={cx({ [styles.root]: styles.root, }, props.className, {
    [styles.noPaddingBottom]: props.paddingBottom === 'noPadding', [styles.bottomImage]: props.layout === 'bottomImage'
  })}>
    <Container>
      {(props.textWithImageAndIcons || []).filter(Boolean).map((item, i) => (
        <div className={styles.logocontainer}>
          {item.title ? (<TitleBlock isLarger><span dangerouslySetInnerHTML={{ __html: item.title || ""}} /></TitleBlock>) : null}
          {item.subtitle ? <div className={styles.subtitle} dangerouslySetInnerHTML={{ __html: marked(item.subtitle || "") }} /> : null}
          {item.image || item.video || item.form ? (
          <div className={cx({ [styles.flex]: styles.flex, }, props.className, {
            [styles.narrow]: props.layout === 'isNarrow', 
            [styles.flexStart]: item.layout === 'alignTop'
          })}>
            <div key={i} className={cx({ [styles.img]: styles.img, }, props.className, {
              [styles.videoAdjustment]: item.video, [styles.formAdjustment]: item.form
          })}>
            {item.image && !item.form ? (
                <Img 
                loading="lazy"
                className={styles.gridimage}
                objectFit="contain"
                alt={item.image.title}
                title={item.image.title}
                fluid={item.image.childImageSharp.fluid}
                />
            ) : item.video ? (
                  <div className={styles.video}>
                    <iframe
                      src={item.video}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title={item.title}
                    />
                  </div>
            ) : item.form ? (
                <div className={styles.form}>
                  <div className={styles.formInner}>
                    <h3 dangerouslySetInnerHTML={{ __html: item.formSubTitle || "" }} />
                    <HubspotForm id={item.form} portalId={item.portalId} />
                  </div>
                  {item.image ? (
                    <Img
                      loading="lazy"
                      className={styles.gridimage}
                      objectFit="contain"
                      alt={item.image.title}
                      title={item.image.title}
                      fluid={item.image.childImageSharp.fluid}
                    />
                  ) : null }
                </div>
            ) : null}
            </div>
            <div>
              {item.description ? <div className={styles.description} dangerouslySetInnerHTML={{ __html: marked(item.description || "") }} /> : null}
              {props.ppcImageWithContent ? (
                <div className={cx(styles.iconList, {
                  [styles.iconListBlock]: props.layout === 'blockIcons'
                })}>
                  {(props.ppcImageWithContent || []).filter(Boolean).map((item, i) => (
                      <div className={styles.iconItem} key={i}>
                        <div className={styles.iconItemWrap}>
                          {item.icon && item.icon.extension === "svg" ? (
                              <img alt={item.label} title={item.label} src={item.icon.publicURL} loading="lazy" width="72" height="72"/>
                          ) : item.icon && item.icon.childImageSharp ? (
                              <Img
                                  loading="lazy"
                                  objectFit="contain"
                                  alt={item.label}
                                  title={item.label}
                                  fluid={item.icon.childImageSharp.fluid}
                              />
                          ) : null}
                          {item.label ? <h3>{item.label}</h3> : null}
                        </div>
                      </div>
                  ))}
                </div>
              ) : null}
              {item.text ? <div className={styles.text} dangerouslySetInnerHTML={{ __html: marked(item.text || "") }} /> : null}
            </div>
          </div>
          ) : (
            item.text ? <div className={styles.centerText} dangerouslySetInnerHTML={{ __html: marked(item.text || "") }} /> : null
          ) }
          {props.secondImage ? (
            <div className={styles.mainimage}>
                {props.secondImage.extension === "svg" ? (
                <img alt={props.secondImage.name} title={props.secondImage.name} src={props.secondImage.publicURL} loading="lazy" width="500" height="500"/>
                ) : props.secondImage.childImageSharp ? (
                <Img
                    loading="lazy"
                    objectFit="fit"
                    alt={props.secondImage.name}
                    title={props.secondImage.name}
                    fluid={props.secondImage.childImageSharp.fluid}
                />
                ) : null}
            </div>
          ) : null}
          {item.buttonText ? (
            <Link className={styles.button} to={`${item.buttonUrl}`}>{item.buttonText}</Link>
          ) : null}
        </div>
      ))}
    </Container>
  </div>
)

export default TextWithImageAndIcons