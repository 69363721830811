import React from "react"
import cx from "classnames"
import marked from "marked"
import Container from "components/Container"
import Img from "gatsby-image/withIEPolyfill"
import TitleBlock from "./TitleBlock"
import styles from "./Testimonial.module.css"

export const Testimonial = (props) => (
    <div className={cx({ [styles.root]: styles.root, }, props.data.className, {
      [styles.gray]: props.data.bgColor === 'isGray', [styles.gradient]: props.data.bgColor === 'isGradient', [styles.noPaddingTop]: props.data.paddingTop === 'noPadding',
    })}>
      <Container>
        <TitleBlock isLarger>{props.data.title}</TitleBlock>
        <div className={styles.quoteContainer}>
            <div className={styles.subTitle} dangerouslySetInnerHTML={{ __html: marked(props.data.subTitle || "") }} />
            <div className={styles.quote} dangerouslySetInnerHTML={{ __html: marked(props.data.description || "") }} />
            {props.data.image ? (
                <div className={styles.imageContainer}>
                  {props.data.image.extension === "svg" ? (
                    <img alt={props.data.title} title={props.data.title} src={props.data.image.publicURL} loading="lazy" width="225" height="200"/>
                  ) : props.data.image.childImageSharp ? (
                    <Img
                      loading="lazy"
                      className={styles.img}
                      objectFit="contain"
                      alt={props.data.title}
                      title={props.data.title}
                      fluid={props.data.image.childImageSharp.fluid}
                    />
                  ) : null}
                  <span className={styles.imageTitle}>{props.data.secondTitle}</span>
                </div>
            ) : null}
        </div>
      </Container>
    </div>
  )
  
  export default Testimonial
  