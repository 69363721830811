import React from "react"
// import * as blobs from "blobs/v2"
import { Link } from "gatsby"
import marked from "marked"
import cx from "classnames"
import Img from "gatsby-image/withIEPolyfill"
import Container from "components/Container"
import TitleBlock from "./TitleBlock"
import styles from "./BlobIcons.module.css"

export const Icon = (props) => {
  return (
    <div className={styles.item}>
      {props.icon ? (
        props.icon.extension === "svg" ? (
          <div className={styles.image}>
            <img alt={props.imageAltText ? props.imageAltText : props.title} title={props.title} src={props.icon.publicURL} width="150" height="150" loading="lazy"/>
          </div>
        ) : (
          <div className={styles.image}>
            <Img
              loading="lazy"
              objectFit="contain"
              alt={props.imageAltText ? props.imageAltText : props.title}
              title={props.title}
              fluid={props.icon.childImageSharp.fluid}
            />
          </div>
        )
      ) : null}
      <div className={styles.textContainer}>
        {props.title ? (
          props.link ? (
            <h3><a href={props.link}>{props.title}</a></h3>
          ) : ( <h3>{props.title}</h3> )
        ) : null}
        {props.description ? (
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: marked(props.description || "") }}
          />
        ) : null}
      </div>
    </div>
  )
}

export const BlobIcons = (props) => (
  <div className={cx({ [styles.root]: styles.root, }, props.className, {
    [styles.isGray]: props.bgColor === 'isGray'
  })}>
    <Container className={styles.innerContainer}>
      <TitleBlock isLarger>
        <span dangerouslySetInnerHTML={{ __html: props.title || "" }} />
        </TitleBlock>
        {props.description ? <div className={styles.desc} dangerouslySetInnerHTML={{ __html: marked(props.description || "") }} /> : null}
      <div className={styles.grid}>
        {(props.blobIcons || []).filter(Boolean).map((item, i) => (
          <Icon key={i} index={i} {...item} />
        ))}
      </div>
      {props.buttonText ? (
            <Link className={styles.button} to={`${props.buttonUrl}`}>{props.buttonText}</Link>
          ) : null}
    </Container>
  </div>
)

export default BlobIcons
