import React from "react"
import marked from "marked"
import Container from "components/Container"
import TitleBlock from "./TitleBlock"
import styles from "./GradientedGridList.module.css"

export const GradientedGridList = (props) => (
  <Container className={styles.root}>
    <TitleBlock isColored>{props.title}</TitleBlock>
    {(props.gradientedGridList || []).length > 0 ? (
      <div className={styles.list}>
        {props.gradientedGridList.filter(Boolean).map((item, i) => (
          <div className={styles.item} key={i}>
            <h3>{item.title}</h3>
            <div dangerouslySetInnerHTML={{ __html: marked(item.text || "") }} />
          </div>
        ))}
      </div>
    ) : null}
  </Container>
)

export default GradientedGridList
