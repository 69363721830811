import React from "react"
import { Link } from "gatsby"
import Container from "components/Container"
import Img from "gatsby-image/withIEPolyfill"
import TitleBlock from "./TitleBlock"
import styles from "./CaseStudyPostsSection.module.css"

export const CaseStudyPostsSection = (props) => {
    return (
    <div className={styles.customers}>
        <Container>
            <TitleBlock isLarger><span dangerouslySetInnerHTML={{ __html: props.title || ""}} /></TitleBlock>
        <div className={styles.customerslist}>
            {(props.casestudies || []).filter(Boolean).map(({ node }) => (
            <div className={styles.customersitem} key={node.fields.slug}>
                <div className={styles.customersimage}>
                <Img
                    width="150"
                    height="100"
                    fadeIn={false}
                    loading="lazy"
                    objectFit="scale-down"
                    alt={node.frontmatter.title}
                    title={node.frontmatter.title}
                    fluid={node.frontmatter.logo.childImageSharp.fluid}
                />
                </div>
                {node.frontmatter.mainTitle ? (
                <h3 className={styles.customertitle}>{node.frontmatter.mainTitle}</h3>
                ) : null}
                <p className={styles.customersquote}>
                {node.frontmatter.quote}
                </p>
                <Link to={`/${node.frontmatter.url}`} className={styles.customerslink}>
                Read more ➜
                </Link>
            </div>
            ))}
        </div>
        {props.buttonUrl ? (
            <Link className={styles.button} to={`/${props.buttonUrl}`}>{props.buttonText}</Link>
        ): null}
        </Container>
    </div>
    )
  }

  export default CaseStudyPostsSection