import React, { useState } from "react"
import cx from "classnames"
import marked from "marked"
import Img from "gatsby-image/withIEPolyfill"
import { Link } from "gatsby"
import { useSpring, animated } from "react-spring"
import * as blobs from "blobs/v2"
import Container from "components/Container"
import TitleBlock from "./TitleBlock"
import styles from "./PageBlobIconList.module.css"

const ServicesBlockLink = ({ node, ...props }) => {
    const [blob, setBlob] = useState(
      blobs.svgPath({ seed: props.index, extraPoints: 4, randomness: 3, size: 300 })
    )
    const onChange = () =>
      setBlob(
        blobs.svgPath({
          seed: props.index + Math.round(Math.random() * 100),
          extraPoints: 4,
          randomness: 3,
          size: 300,
        })
      )
    const springProps = useSpring({ d: blob })
  
    return (
      <article className={styles.serviceitem}>
        <Link
          to={`/${node.frontmatter.url}`}
          className={styles.serviceimage}
          onMouseEnter={onChange}
          onMouseLeave={onChange}
        >
          <svg viewBox="0 0 300 300">
            <linearGradient id="gradient" gradientUnits="userSpaceOnUse">
              <stop stopColor="#4ebfae" />
              <stop offset="1" stopColor="#00aeca" />
            </linearGradient>
            <animated.path {...springProps} fill="#ebebeb" />
          </svg>
          {node.frontmatter.image && node.frontmatter.image.extension === "svg" ? (
            <img
              width="190"
              height="190"
              loading="lazy"
              alt={node.frontmatter.title}
              title={node.frontmatter.title}
              src={node.frontmatter.image.publicURL}
            />
          ) : node.frontmatter.image && node.frontmatter.image.childImageSharp ? (
            <Img
              loading="lazy"
              width="190"
              height="190"
              fadeIn={false}
              objectFit="contain"
              alt={node.frontmatter.title}
              title={node.frontmatter.title}
              fluid={node.frontmatter.image.childImageSharp.fluid}
            />
          ) : null}
        </Link>
        <h3><Link to={`/${node.frontmatter.url}`}>{node.frontmatter.title}</Link></h3>
      </article>
    )
  }
  
  // eslint-disable-next-line
  export const PageBlobIconList = (props) => (
    <div className={cx({ [styles.services]: styles.services, }, props.className, {
        [styles.gray]: props.bgColor === 'isGray', [styles.gradient]: props.bgColor === 'isGradient', [styles.noPaddingTop]: props.paddingTop === 'noPadding', [styles.smallPaddingTop]: props.paddingTop === 'smallPaddingTop'
        })}>
        <Container className={styles.root}>
            <TitleBlock isLarger>{props.title}</TitleBlock>
            <div
                className={styles.servicesdesc}
                dangerouslySetInnerHTML={{ __html: marked(props.description || "") }}
            />

            <div className={styles.serviceslist}>
                {(props.pages || []).filter(Boolean).map(({ node }, i) => (
                <ServicesBlockLink node={node} key={i} index={i} />
                ))}
            </div>
        </Container>
    </div>
  )

  export default PageBlobIconList