import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import Container from "components/Container"
import TitleBlock from "./TitleBlock"
import cx from "classnames"
import marked from "marked"
import styles from "./PpcContentGrid.module.css"

export const PpcContentGrid = (props) => (
  <div className={cx(props.className, {
      [styles.gray]: props.bgColor === 'isGray',
      [styles.gradient]: props.bgColor === 'isGradient',
      [styles.twocol]: props.layout === 'twocol',
      [styles.threecol]: props.layout === 'threecol',
      [styles.threecolBordered]: props.layout === 'threecolBordered',
      [styles.twocolWithImage]: props.layout === 'twocolWithImage',
    })}>
    <Container className={cx(styles.root, {
      [styles.noPaddingTop]: props.paddingTop === 'noPadding',
    })}>
      <TitleBlock>{props.title}</TitleBlock>
      {props.subTitle ? <div className={styles.subTitle} dangerouslySetInnerHTML={{ __html: marked(props.subTitle || "") }} /> : null}
      {props.ppcContentGrid ? (
        <div className={styles.imageWrap}>
          {props.description ? <div className={styles.desc} dangerouslySetInnerHTML={{ __html: marked(props.description || "") }} /> : null}
          <div className={styles.imageList}>
            {(props.ppcContentGrid || []).filter(Boolean).map((item, i) => (
              <div className={styles.itemImageWrap} key={i}>
                {item.image && item.image.extension === "svg" ? (
                  <div className={styles.itemImage}>
                    <img alt="Partner Image" title="Partner Image" src={item.image.publicURL} width="200" height="200" loading="lazy"/>
                  </div>
                ) : item.icon && item.icon.childImageSharp ? (
                  <div className={styles.itemImage}>
                    <Img
                      loading="lazy"
                      className={styles.imageMain}
                      objectFit="contain"
                      alt="Partner Image"
                      title="Partner Image"
                      fluid={item.image.childImageSharp.fluid}
                    />
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      ) : (
        props.description ? <div className={styles.desc} dangerouslySetInnerHTML={{ __html: marked(props.description || "") }} /> : null
      )}
      <div className={styles.list}>
        <div className={styles.inner}>
          {(props.iconsGridList || []).filter(Boolean).map((item, i) => (
            <div className={styles.item} key={i}>
              <div className={styles.itemWrap}>
                {item.icon && item.icon.extension === "svg" ? (
                  <div className={styles.icon}>
                    <img alt={item.title} title={item.title} src={item.icon.publicURL} width="60" height="60" loading="lazy"/>
                  </div>
                ) : item.icon && item.icon.childImageSharp ? (
                  <div className={styles.icon}>
                    <Img
                      loading="lazy"
                      className={styles.iconMain}
                      objectFit="contain"
                      alt={item.title}
                      title={item.title}
                      fluid={item.icon.childImageSharp.fluid}
                    />
                  </div>
                ) : null}
                <div className={styles.heading}>
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {props.buttonText ? (
        <Link className={styles.button} to={`${props.buttonUrl}`}>{props.buttonText}</Link>
      ) : null}
    </Container>
  </div>
)

export default PpcContentGrid
