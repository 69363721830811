import React from "react"
import { Link } from "gatsby"
import Container from "components/Container"
import cx from "classnames"
import Img from "gatsby-image/withIEPolyfill"
import TitleBlock from "./TitleBlock"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import styles from "./CaseStudySliderSection.module.css"

function CustomArrowPrev(props) {
  const { className, style, onClick } = props;
  return (
    // eslint-disable-next-line
    <div
      className={cx(className, styles.slickArrow, styles.slickArrowPrev)}
      style={{...style}}
      onClick={onClick}
    />
  );
}

function CustomArrowNext(props) {
  const { className, style, onClick } = props;
  return (
    // eslint-disable-next-line
    <div
      className={cx(className, styles.slickArrow, styles.slickArrowNext)}
      style={{...style}}
      onClick={onClick}
    />
  );
}

const settings = {
    dots: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    prevArrow: <CustomArrowPrev />,
    nextArrow: <CustomArrowNext />,
    dotsClass: cx(styles.slickDots, 'slick-dots'),
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                arrows: false,
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        },
        {
            breakpoint: 800,
            settings: {
                arrows: false,
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 680,
            settings: {
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }
    ]
}

export const CaseStudySliderSection = (props) => {
    return (
        <div className={cx(styles.customers, {
            [styles.gray]: props.bgColor === 'isGray',
            [styles.gradient]: props.bgColor === 'isGradient'
        })}>
            <Container>
            <TitleBlock isLarger><span dangerouslySetInnerHTML={{ __html: props.title || ""}} /></TitleBlock>
                <div className={styles.customerslist}>
                    <Slider {...settings} className={styles.caseStudySliderMain}>
                        {(props.casestudies || []).filter(Boolean).map(({ node }) => (
                            <div className={styles.customersitem} key={node.fields.slug}>
                                <div className={styles.customersitemwrap} key={node.fields.slug}>
                                    <div className={styles.customersimage}>
                                    <Img
                                        width="150"
                                        height="100"
                                        objectFit="contain"
                                        loading="lazy"
                                        alt={node.frontmatter.title}
                                        title={node.frontmatter.title}
                                        fluid={node.frontmatter.logo.childImageSharp.fluid}
                                    />
                                    </div>
                                    {node.frontmatter.mainTitle ? (
                                    <h3 className={styles.customertitle}>{node.frontmatter.mainTitle}</h3>
                                    ) : null}
                                    <p className={styles.customersquote}>
                                    {node.frontmatter.quote}
                                    </p>
                                    <Link to={`/${node.frontmatter.url}`} className={styles.customerslink}>
                                    Read more ➜
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            {props.buttonUrl ? (
                <Link className={styles.button} to={`/${props.buttonUrl}`}>{props.buttonText}</Link>
            ): null}
            </Container>
        </div>
    )
  }

  export default CaseStudySliderSection