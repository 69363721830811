import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import Container from "components/Container"
import TitleBlock from "components/Blocks/TitleBlock"
import styles from "./GridWithIcons.module.css"

export const GridWithIcons = (props) => (
  <Container className={styles.root}>
    <TitleBlock>{props.title}</TitleBlock>
    {(props.gridWithIconsList || []).length > 0 ? (
      <ul className={styles.list}>
        {props.gridWithIconsList.filter(Boolean).map((item, i) => (
          <li key={i} className={styles.item}>
            <div className={styles.icon}>
              {item.icon && item.icon.extension === "svg" ? (
                <img alt={item.title} title={item.title} src={item.icon.publicURL} loading="lazy" width="80" height="80"/>
              ) : item.icon && item.icon.childImageSharp ? (
                <Img
                  loading="lazy"
                  objectFit="contain"
                  alt={item.title}
                  title={item.title}
                  fluid={item.icon.childImageSharp.fluid}
                />
              ) : null}
            </div>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </li>
        ))}
      </ul>
    ) : null}
  </Container>
)

export default GridWithIcons
