import React from "react"
import { Link } from "gatsby"
import cx from "classnames"
import TitleBlock from "./TitleBlock"
import Container from "components/Container"
import styles from "./PpcSpeakingSession.module.css"
import { SingleArrowRight } from "components/Icons"

export const PpcSpeakingSession = (props) => (
  <div className={cx(props.className, {
    [styles.gray]: props.bgColor === 'isGray', [styles.gradient]: props.bgColor === 'isGradient'
  })}>
    <Container className={styles.root}>
      {(props.ppcSpeakingSession || []).length > 0 ? (
        <div className={styles.list}>
          <TitleBlock className={styles.title}><span dangerouslySetInnerHTML={{ __html: props.title }} /></TitleBlock>
          <ul className={styles.listInner}>
            {props.ppcSpeakingSession.filter(Boolean).map((item, i) => (
              <li key={i} className={styles.item}>
                <div className={styles.listItemWrapper}>
                  <p>{item.label}</p>
                  <Link className={styles.link} to={`${item.link}`}>Learn More {SingleArrowRight}</Link>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </Container>
  </div>
)

export default PpcSpeakingSession
