import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import TitleBlock from "./TitleBlock"
import Container from "components/Container"
import * as blobs from "blobs/v2"
import styles from "./IconsPath.module.css"

export const IconsPath = (props) => (
  <Container className={styles.root}>
    {props.title ? <TitleBlock isColored>{props.title}</TitleBlock> : null}
    <div className={styles.wrap}>
      {(props.iconsPathList || []).filter(Boolean).map((item, i) => (
        <div className={styles.item} key={i}>
          <div className={styles.info}>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </div>

          {item.icon ? (
            <div className={styles.icon}>
              <div>
                <svg viewBox="0 0 300 300">
                  <linearGradient id="gradient" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4ebfae" />
                    <stop offset="1" stopColor="#00aeca" />
                  </linearGradient>
                  <path
                    d={blobs.svgPath({ seed: i, extraPoints: 5, randomness: 7, size: 300 })}
                    fill="url(#gradient)"
                  />
                </svg>
                <Img
                  loading="lazy"
                  objectFit="contain"
                  alt={item.title}
                  title={item.title}
                  fluid={item.icon.childImageSharp.fluid}
                />
              </div>
            </div>
          ) : null}

          <div className={styles.w} />
        </div>
      ))}
    </div>
  </Container>
)

export default IconsPath
