import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import TitleBlock from "components/Blocks/TitleBlock"
import Container from "components/Container"
import styles from "./GridWithImage.module.css"

export const Item = ({ title, description }) => (
  <li className={styles.item}>
    <h3>{title}</h3>
    <p>{description}</p>
  </li>
)

export const GridWithImage = (props) => (
  <Container className={styles.root}>
    <TitleBlock isColored>{props.title}</TitleBlock>
    <div className={styles.wrap}>
      {(props.gridWithImageList || []).length > 0 ? (
        <ul className={styles.list}>
          {props.gridWithImageList
            .filter(Boolean)
            .slice(0, 2)
            .map((item, i) => (
              <Item key={i} {...item} />
            ))}
        </ul>
      ) : null}
      {props.image && props.image.extension === "svg" ? (
        <div className={styles.image}>
          <img alt={props.title} title={props.title} src={props.image.publicURL} width="300" height="300" loading="lazy"/>
        </div>
      ) : props.image && props.image.childImageSharp ? (
        <div className={styles.image}>
          <Img
            loading="lazy"
            objectFit="contain"
            alt={props.title}
            title={props.title}
            fluid={props.image.childImageSharp.fluid}
          />
        </div>
      ) : null}
    </div>
    {(props.gridWithImageList || []).length > 0 ? (
      <ul className={styles.list}>
        {props.gridWithImageList
          .filter(Boolean)
          .slice(2)
          .map((item, i) => (
            <Item key={i} {...item} />
          ))}
      </ul>
    ) : null}
  </Container>
)

export default GridWithImage
