import React from "react"
import marked from "marked"
import { Link } from "gatsby"
import Container from "components/Container"
import cx from "classnames"
import TitleBlock from "./TitleBlock"
import styles from "./ContentWithList.module.css"

export const ContentWithList = (props) => (
  <div className={cx({ [styles.root]: styles.root, }, props.className, {
    [styles.noPaddingBottom]: props.paddingBottom === 'noPadding',
    [styles.isGradient]: props.bgColor === 'isGradient',
    [styles.gray]: props.bgColor === 'isGray'
  })}>
    <Container>
      <div className={styles.logocontainer}>
        {props.subTitle ? <div className={styles.subtitle} dangerouslySetInnerHTML={{ __html: marked(props.subTitle || "") }} /> : null}
        <TitleBlock isLarger>
          <span dangerouslySetInnerHTML={{ __html: props.title || ""}} />
        </TitleBlock>
        {props.text ? <div className={styles.text} dangerouslySetInnerHTML={{ __html: marked(props.text || "") }} /> : null}
        {props.buttonText ? (
          <Link className={styles.button} to={`${props.buttonUrl}`}>{props.buttonText}</Link>
        ) : null}
      </div>
      <div className={styles.list}>
        {(props.contentWithList || []).filter(Boolean).map((list, i) => (
          <div className={styles.listinner}>
            <h3>{list.secondTitle}</h3>
            <div className={styles.listmain}>
              <ul>
                {(list.list || []).filter(Boolean).map((item, i) => (
                  <li>
                    {item.link ? (
                      <Link to={`${item.link}`}>{item.label}</Link>
                    ) : item.label}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </Container>
  </div>
)

export default ContentWithList
