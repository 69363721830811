import React from "react"
import { Link } from "gatsby"
import cx from "classnames"
import marked from "marked"
import Container from "components/Container"
import Img from "gatsby-image/withIEPolyfill"
import TitleBlock from "./TitleBlock"
import styles from "./PpcImageWithContent.module.css"

export const PpcImageWithContent = (props) => (
  <div className={cx({ [styles.root]: styles.root, }, props.className, {
    [styles.gray]: props.bgColor === 'isGray',
    [styles.gradient]: props.bgColor === 'isGradient',
    [styles.noPaddingTop]: props.paddingTop === 'noPadding',
    [styles.reverse]: props.layout === 'reverse',
  })}>
    <div className={styles.titleTop}>
      {props.subTitle && props.layout === 'titleTop' ? (
        <h3 className={styles.subTitle}>{props.subTitle}</h3>
      ) : null}
      {props.title && props.layout === 'titleTop' ? (
        <TitleBlock isLarger>{props.title}</TitleBlock>
      ) : null}
    </div>
    <Container>
        <div className={styles.rootWrap}>
          <div className={styles.image}>
            {props.image.extension === "svg" ? (
              <img alt={props.imageAltText ? props.imageAltText : props.name} title={props.name} src={props.image.publicURL} width="600" height="500" loading="lazy"/>
            ) : props.image.childImageSharp ? (
              <Img
                loading="lazy"
                className={styles.imageMain}
                objectFit="contain"
                alt={props.imageAltText ? props.imageAltText : props.name}
                title={props.name}
                fluid={props.image.childImageSharp.fluid}
              />
            ) : null}
          </div>
          <div className={styles.content}>
            <div className={styles.contentWrap}>
              {props.subTitle ? (
                <h3 className={styles.subTitle}>{props.subTitle}</h3>
              ) : null}
              {props.title ? (
                <TitleBlock isLarger>{props.title}</TitleBlock>
              ) : null}
              {props.ppcImageWithContent ? (
                <div className={styles.iconList}>
                  {(props.ppcImageWithContent || []).filter(Boolean).map((item, i) => (
                      <div className={styles.iconItem} key={i}>
                        <div className={styles.iconItemWrap}>
                          {item.icon && item.icon.extension === "svg" ? (
                              <img alt={item.imageAltText ? item.imageAltText : item.label} title={item.label} src={item.icon.publicURL} width="72" height="72"/>
                          ) : item.icon && item.icon.childImageSharp ? (
                              <Img
                                  loading="lazy"
                                  objectFit="contain"
                                  alt={item.imageAltText ? item.imageAltText : item.label}
                                  title={item.label}
                                  fluid={item.icon.childImageSharp.fluid}
                              />
                          ) : null}
                          {item.label ? <h3>{item.label}</h3> : null}
                        </div>
                      </div>
                  ))}
                </div>
              ) : null}
              <div className={styles.text} dangerouslySetInnerHTML={{ __html: marked(props.text || "") }} />
              {props.buttonText ? (
                <Link className={styles.button} to={`${props.buttonUrl}`}>{props.buttonText}</Link>
              ) : null}
            </div>
          </div>
        </div>
    </Container>
  </div>
)

export default PpcImageWithContent
