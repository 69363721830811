import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import Container from "components/Container"
import TitleBlock from "./TitleBlock"
import * as blobs from "blobs/v2"
import styles from "./ChessGrid.module.css"

export const ChessGrid = (props) => (
  <Container className={styles.root}>
    <TitleBlock>{props.title}</TitleBlock>
    <div className={styles.wrap}>
      {(props.chessGridList || []).filter(Boolean).map((item, i) => (
        <div className={styles.item} key={i}>
          {item.icon ? (
            <div className={styles.image}>
              <svg viewBox="0 0 300 300">
                <linearGradient id="gradient" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#4ebfae" />
                  <stop offset="1" stopColor="#00aeca" />
                </linearGradient>
                <path
                  d={blobs.svgPath({ seed: i, extraPoints: 5, randomness: 7, size: 300 })}
                  fill="url(#gradient)"
                />
              </svg>
              <Img
                loading="lazy"
                objectFit="contain"
                alt={item.imageAltText ? item.imageAltText : item.title}
                title={item.title}
                fluid={item.icon.childImageSharp.fluid}
              />
            </div>
          ) : null}
          <div className={styles.info}>
            <h4>{item.title}</h4>
            <div dangerouslySetInnerHTML={{ __html: item.description }} />
            {/* <a className={styles.link} href={item.link} target="_blank" rel="noopener noreferrer">
                Read more
                </a> */}
          </div>
        </div>
      ))}
    </div>
  </Container>
)

export default ChessGrid
